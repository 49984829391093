<template>
  <!-- 行业RRP定制开发-->
   <div class="wrapper">
    <Header></Header>
    <div class="body">
      <div class="pic">
        <img :src="page1">
      </div>
      <div class="link">
        <div class="title">
          <div class="title-big">产品概述</div>
          <div class="title-small">让您更好的了解我们整个产品</div>
        </div>
        <div class="form1">按<span class="color">现代管理思想</span>设计的企事业单位内部或之间的云管理SAAS远程协同办公系统，符合时代趋势，我们一直用最新的设计理念和思想开发云管理SAAS系统，企事业单位资源随用随得，传统思想是基于以单个企业为中心的孤城式管理，现代管理思想是基于<span class="color">企事业之间高效协同与社会资源开放式资源管理</span>，企业随时高效调用云端社会资源，按需付费，随用随得。</div>
      </div>
      <Reserve></Reserve>
      <div class="sass">
        <div class="link">
          <div class="title2">
            <div class="title2-big">云管理SAAS平台核心功能</div>
            <div class="title2-small">以公司法为基础，深度还原公司实际经营</div>
          </div>
          <div class="left">
            <div class="left_icon">
              <img :src="sass1">
            </div>
            <div class="left_content">
              <div class="left_title">01 数字企业法人</div>
              <div class="left_text1">主要企业高层经营或管理企业，制定公司决策</div>
              <div class="left_text2">法人认证、股东会、董事会、监事会、组织架</div>
            </div>
          </div>
          <div class="right">
            <div class="right_content">
              <div class="right_title">02 人事部</div>
              <div class="right_text1">人事部主要用于企业进行人力资源管理，主要包含以下功能</div>
              <div class="right_text2">人员资料管理、人员薪酬管理、人员离职/入职管理</div>
            </div>
            <div class="right_icon">
              <img :src="sass2">
            </div>
          </div>
          <div class="left">
            <div class="left_icon">
              <img :src="sass3">
            </div>
            <div class="left_content">
              <div class="left_title">03 营销部</div>
              <div class="left_text1">营销部主要用于制定企业的一些营销计划，主要包含以下功能</div>
              <div class="left_text2">产品管理、促销管理、广告管理、渠道管理、价格管理、数据统计</div>
            </div>
          </div>
          <div class="right">
            <div class="right_content">
              <div class="right_title">04 采购部</div>
              <div class="right_text1">采购部主要用于企业进行采购活动，主要包含以下功能</div>
              <div class="right_text2">采购计划、采购清单、合同管理、采购订单、供应商、在线询盘、在线采购</div>
            </div>
            <div class="right_icon">
              <img :src="sass4">
            </div>
          </div>
          <div class="left">
            <div class="left_icon">
              <img :src="sass5">
            </div>
            <div class="left_content">
              <div class="left_title">05 销售部</div>
              <div class="left_text1">销售部主要用于企业的一些销售管理，主要包含以下功能</div>
              <div class="left_text2">会员管理、商机客户、销售公共池、合同管理、订单管理、报价管理、在线报价</div>
            </div>
          </div>
          <div class="right">
            <div class="right_content">
              <div class="right_title">06 财务部</div>
              <div class="right_text1">管理企业财务，查询企业账务，主要包含以下功能</div>
              <div class="right_text2">共管账户、账务查询、提现管理、收入状况曲线图</div>
            </div>
            <div class="right_icon">
              <img :src="sass6">
            </div>
          </div>
          <Reserve></Reserve>
        </div>
      </div>
      <div class="three">
        <div class="link">
          <div class="title2">
            <div class="title2-big">三步定制行业ERP</div>
            <div class="title2-small">打造属于自己的ERP办公软件</div>
          </div>
          <div class="three_icons">
            <div class="icon1">
              <img :src="three3">
            </div>
            <div class="icon2">
              <img :src="three4">
            </div>
            <div class="icon1">
              <img :src="three2">
            </div>
            <div class="icon2">
              <img :src="three4">
            </div>
            <div class="icon1">
              <img :src="three1">
            </div>
          </div>
          <div class="forms2">
            <div class="form2">
              <div class="form2_title">确认需求</div>
              <div class="form2_text">
                <div>与客服人员取得联系说明意向</div>
                <div>专业技术人员和您对接需求，</div>
                <div>制定解决方案，方案满意后给您提供设计图去确认</div>
              </div>
            </div>
            <div class="form2">
              <div class="form2_title">开发实施</div>
              <div class="form2_text">
                <div>开发工程师实施开发，提交测试</div>
                <div>测试工程师测试反馈给开发</div>
                <div>开发完善系统交付最后版本</div>
              </div>
            </div>
            <div class="form2">
              <div class="form2_title">交付验收</div>
              <div class="form2_text">
                <div>您检测有问题我们会继续完善，没有问题即交付成功</div>
                <div>验收后提供验收单给我们，我们即可安排工程师进行上线工作</div>
              </div>
            </div>
          </div>
          <Reserve></Reserve>
        </div>
      </div>
      <Record></Record>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Reserve from '../components/reserve.vue'
import Record from '../components/record.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'page2',
  components: {
    Header,
    Reserve,
    Record,
    Footer
  },
  data () {
    return {
      page1: require('@/assets/img/page2.png'),
      sass1: require('@/assets/img/sass1.png'),
      sass2: require('@/assets/img/sass2.png'),
      sass3: require('@/assets/img/sass3.png'),
      sass4: require('@/assets/img/sass4.png'),
      sass5: require('@/assets/img/sass5.png'),
      sass6: require('@/assets/img/sass6.png'),
      three1: require('@/assets/img/three1.png'),
      three2: require('@/assets/img/three2.png'),
      three3: require('@/assets/img/three3.png'),
      three4: require('@/assets/img/箭头.png')
    }
  }
}
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  z-index: 1;
  margin: 0.42rem -0.08rem 0 -0.08rem;
}
.pic {
  height: 2.02rem;
  margin-bottom: 0.2rem;
}
img {
 width: 100%;
 height: 100%;
}
.link {
  text-align: center;
  .title {
    margin-bottom: 0.15rem;
    .title-big {
      font-size: 0.17rem;
      font-family: PingFangSC-Medium;
      color: #1e1e1e;
      margin-bottom: 0.06rem;
    }
    .title-small {
      font-family: PingFangSC-Regular;
      color: #666666
    }
  }
  .form1 {
    margin: 0 0.16rem 0 0.16rem;
    padding: 0.12rem 0.12rem 0.19rem 0.12rem;
    border-radius: 0.02rem;
    box-shadow: 0 0.01rem 0.07rem 0 rgba(0, 0, 0, 0.11);
    font-family: PingFangSC-Medium;
    text-align: start;
  }
}
.color {
  color: #EE602C;
}
.sass {
  height: 9.965rem;
  background-color: #f7f7f7;
  .link {
    text-align: center;
    .left {
      margin: 0 0.18rem 0.24rem 0.24rem;
      display: flex;
      .left_icon {
        width: 1.19rem;
        height: 1.19rem;
        margin-right: 0.18rem;
      }
      .left_content {
        text-align: start;
        width: 1.855rem;
        .left_title {
          font-size: 0.14rem;
          font-family: PingFangSC-Medium;
          margin-bottom: 0.08rem;
        }
        .left_text1 {
          font-family: PingFangSC-Regular;
          color: #666666;
          margin-bottom: 0.07rem;
        }
        .left_text2 {
          font-family: PingFangSC-Medium;
          color: #333333;
        }
      }
    }
    .right {
      margin: 0 0.18rem 0.24rem 0.24rem;
      display: flex;
      align-items: center;
      .right_icon {
        width: 1.19rem;
        height: 1.19rem;
      }
      .right_content {
        width: 1.855rem;
        text-align: start;
        margin-right: 0.18rem;
        .right_title {
          font-size: 0.14rem;
          font-family: PingFangSC-Medium;
          margin-bottom: 0.08rem;
        }
        .right_text1 {
          font-family: PingFangSC-Regular;
          color: #666666;
          margin-bottom: 0.07rem;
        }
        .right_text2 {
          font-family: PingFangSC-Medium;
          color: #333333
        }
      }
    }
  }
}
.title2 {
  padding-top: 0.2rem;
  margin-bottom: 0.15rem;
  .title2-big {
    font-size: 0.17rem;
    font-family: PingFangSC-Medium;
    color: #1e1e1e;
    margin-bottom: 0.06rem;
  }
  .title2-small {
    font-family: PingFangSC-Regular;
    color: #666666
  }
}
.three {
  .three_icons {
    margin: 0 0 0.13rem 0.39rem;
    display: flex;
    align-items: center;
    .icon1 {
      width: 0.62rem;
      height: 0.62rem;
      margin-right: 0.18rem;
    }
    .icon2 {
      width: 0.19rem;
      height: 0.19rem;
      margin-right: 0.185rem;
    }
  }
  .forms2 {
    display: flex;
    .form2 {
      width: 1rem;
      margin-left: 0.19rem;
      .form2_title {
        font-family: PingFangSC-Medium;
        color: #333333
      }
      .form2_text {
        text-align: left;
        font-family: PingFangSC-Regular;
        color: #999990
      }
    }
  }
}
</style>
